<script>
import { BAlert } from 'bootstrap-vue'

export default {
  name: 'NewSassBanner',
  components: {
    BAlert,
  },
  data() {
    return {
      newSaaSHost: process.env.VUE_APP_NEW_SAAS_URL,
      showBanner: false,
    }
  },
  mounted() {
    if (this.$store.state.auth.user.has_saas_access) {
      this.showBanner = true
    }
  },
}
</script>

<template>
  <div v-show="showBanner" class="saas_access_banner_wrapper">
    <h4 class="saas_access_banner_title">{{ $t('saas_access_banner_title') }}</h4>
    <p class="saas_access_banner_description">
      {{ $t('saas_access_banner_description') }}
    </p>
    <p class="saas_access_banner_description">
      {{ $t('saas_access_banner_secondary_description') }}
    </p>
    <a target="_blank" :href="newSaaSHost" class="saas_access_banner_cta">{{
      $t('saas_access_banner_cta')
    }}</a>
  </div>
</template>

<style scoped>
.saas_access_banner_wrapper {
  padding: 1.15rem 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  background-color: #fff;
  border-color: transparent;
  box-shadow: 0 4px 24px 0 rgba(14, 26, 26, 0.1);
  color: #fff;
  background-color: #435993;
  display: flex;
  flex-direction: column;
}

.saas_access_banner_content {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.saas_access_banner_title {
  margin-bottom: 16px;
  font-size: 1.5rem;
  color: inherit;
}

.saas_access_banner_description {
  margin-bottom: 0.5rem;
}

.saas_access_banner_secondary_content_ {
  font-size: 12px;
}

.saas_access_banner_cta {
  color: #fff;
  padding: 12px 16px;
  border: 1px solid #fff;
  background-color: #435993;
  min-width: fit-content;
  text-align: center;
  border-radius: 4px;
  margin: auto;
  font-weight: 600;
  margin-top: 16px;
}
</style>
